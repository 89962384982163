import { FiArrowUp, FiArrowDown, FiMinus } from "react-icons/fi";
import "./integerComparison.css"

const IntegerComparison = ({prev, current, className}) => {

  const isEqual = prev === current 

  return(
    <div className={className + " integerComparison"}>
      {isEqual && <FiMinus className="integerComparisonSign"/>}
      {prev > current && <FiArrowDown color="#E05656" className="integerComparisonSign"/>}
      {current > prev && <FiArrowUp color="#4CC790" className="integerComparisonSign"/>}
     <p className="integerComparisonNumber">{Math.abs(current - prev)}</p>
    </div>
  )
}

export default IntegerComparison;