import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import './sign.css';


const SignIn = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

  const handleSubmit = () => {

    axios.post(API_ENDPOINT + "/login", {user: {email: email, password: password}})
    .then((response) => {
      console.log(response.headers.authorization);
      localStorage.setItem("jwt", response.headers.authorization);
      window.location = "/";
    })
    .catch(error => {
      if (error.response) {
        setErrorMessage(error.response.data.status.message);
      }
    });
  }
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("")


  // useEffect(() => {
  //   axios.get(API_ENDPOINT + "/drills/" + params.id).then(response => {
  //     setDrill(response.data)
  //     setForm(response.data.shots.map(({ created_at, updated_at, drill_id, ...rest }) => rest))
  //   })
  // }, [params.id, API_ENDPOINT])

  return (
    <>
      <div className="signForm">
        <p className="signLabel">Email:</p>
        <input type="email" className="signInput" value={email} onChange={e => setEmail(e.target.value)}></input>
        <p className="signLabel">Password:</p>
        <input type="password" className="signInput" value={password}  onChange={e => setPassword(e.target.value)}></input>
      </div>
      {errorMessage && <p>{errorMessage}</p>}
      <div className="signButton" onClick={()=> handleSubmit()}>Sign in</div>
      <Link to="/signup" className="signAltLink">create account</Link>
    </>

  )
 
};

export default SignIn;