import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import './run.css';

import CircularPercentage from "../helpers/circularPercentage/CircularPercentage"
import PoolTable from "../shared/poolTable/PoolTable";
import IntegerComparison from "../helpers/integerComparison/IntegerComparison";

const Run = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

  const handleClick = (id) => {
    const nextForm = form.map((c) => {
      if(c.id === id) {
        return({...c, success: !c.success,})
      } else {
        return c
      }
      
    });
    setForm(nextForm);
  }

  const handleSubmit = () => {
    
    if(submitted) {return}

    setSubmitted(true);
    axios.post(API_ENDPOINT + "/runs/create", {drill_id: drill.id, form}).then((response) => {
      console.log(response);
      setResult({percentage: response.data.percentage, makes: response.data.makes, prev_makes: response.data.prev_makes})
      setShowResult(true);
    });
  }

  const handleTryAgain = () => {
    window.location.reload();
  }
  
  const params = useParams();
  
  const [drill, setDrill] = useState({'name': '', 'diagram': '', 'description': '', 'shots': []});
  const [form, setForm] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState({prev_makes: 0, makes: 0, percentage: 0});

  useEffect(() => {
    axios.get(API_ENDPOINT + "/drills/" + params.id).then(response => {
      setDrill(response.data)
      setForm(response.data.shots.map(({ created_at, updated_at, drill_id, ...rest }) => rest))
    })
  }, [params.id, API_ENDPOINT])

  return (
    <>
      <div className="runShow">
        <h1 className="drillName">{drill.name}</h1>
        <div className="drillBody">
          <PoolTable diagram={drill.diagram} styles={{backgroundColor: "white", borderRadius: "0.5rem", margin: "1rem 0"}}/>

          {showResult && <>
            <div className="resultStats">
              <div>
                <p className="boxLabel f125">shots made</p>
                <CircularPercentage percentage={result.percentage} className="resultWrapper"/>
              </div>
             
              <div>
                <p className="boxLabel f125">last run</p>
                <IntegerComparison prev={result.prev_makes} current={result.makes} className="resultWrapper"/>   
              </div>

            </div>
            <div className="runAgainButtons">
              <div className="button runAgainButton" onClick={() => handleTryAgain()}>try again</div>
              <Link to={"/drills/"+ params.id +"/stats"} className="button runAgainButton">show stats</Link>
            </div>
          
          </>}

          {!showResult && <>
            <div className="runForm">
              {form.map(function(object, i){
                return (
                  <div key={i} className={"tryToggle" + (object.success ? " success" : "")} onClick={() => handleClick(object.id)}>shot {object.name}</div>
                )
              })}
            </div>
            <div className="button submitRunButton" id="btmfx" onClick={() => handleSubmit()}>submit</div>
          </>}

        </div>
      </div>
    </>

  )
 
};

export default Run;