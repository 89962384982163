import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import axios from "axios";
import './drills.css';
import PoolTable from "../shared/poolTable/PoolTable";

const Drills = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
  
  const [drills, setDrills] = useState([]);

  useEffect(() => {
    axios.get(API_ENDPOINT + "/drills/").then(response => {
      setDrills(response.data.drills)
    })
  }, [API_ENDPOINT])


  return (
    <div className="drillList">
      {drills.map((drill, i) => (
        <Link to={"./" + drill.id} key={i}>
          <div className="drillListItem">
            <h3 className="drillListItemName">{drill.name}</h3>
            <PoolTable diagram={drill.diagram}/>
          </div>
        </Link>
      ))}
    </div>
  )
 
};

export default Drills;