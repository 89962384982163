import "./linearPercentage.css"

const LinearPercentage = ({percentage, className}) => {

  return(
    <svg viewBox="0 0 110 7" className={className + " linear-chart"}>
      <line className="line" x1="5" y1="3" x2="105" y2="3" strokeDasharray={percentage + ", 100"}/>
    </svg>
  )
}

export default LinearPercentage;