import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/home/Home";
import Drill from "./components/drill/Drill";
import Run from "./components/run/Run";
import Stats from "./components/stats/Stats";
import SignUp from "./components/sign/signUp";
import SignIn from "./components/sign/signIn";
import NoPage from "./components/NoPage";
import Drills from "./components/drills/Drills";
import User from "./components/user/user";
import axios from "axios";

import './index.css';



axios.defaults.headers.common = {
  'Authorization': localStorage.getItem("jwt")
};

axios.interceptors.response.use(response => {
  return response;
}, error => {
 if (error.response.status === 401) {
  console.log("inauth")
  window.location = "/signin"
 }
 return error;
});


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="drills">
            <Route path="" element={<Drills/>}/>

            <Route path=":id" element={<Drill/>}/>
            <Route path=":id/run" element={<Run/>}/>
            <Route path=":id/stats" element={<Stats/>}/>

          </Route>
          <Route path="/signup" element={<SignUp />}></Route>
          <Route path="/signin" element={<SignIn />}></Route>
          <Route path="/user" element={<User />}></Route>
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);