import "./circularPercentage.css"

const CircularPercentage = ({percentage, className}) => {

  return(
    <svg viewBox="0 0 36 36" className={className + " circular-chart"}>
      <path className="circle"
        strokeDasharray={percentage + ", 100"}
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" style={{fontSize: "75%"}}>{percentage}%</text>
    </svg>
  )
}

export default CircularPercentage;