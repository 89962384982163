const PoolTable = ({diagram, styles}) => {
  
  return (
    <svg viewBox="0 0 28900 16200" xmlns="http://www.w3.org/2000/svg" className="poolTableSVG" style={styles}>

      <defs>
      <linearGradient id="greenGradient" x1="0%" x2="100%" y1="0%" y2="0%">
        <stop offset="0%" stopColor="#75D083" />
        <stop offset="100%" stopColor="#89F79A" />
      </linearGradient>
      </defs>

      <rect x="1250" y="1300" width="26400" height="13600" fill="#50A6C2"/>
      <polygon points="1250,2300 1750,2800 1750,13400 1250,13900" fill="#166d8a"></polygon>
      <polygon points="27650,2300 27150,2800 27150,13400 27650,13900" fill="#166d8a"></polygon>
      <polygon points="2250,1300 26650,1300 26150,1800 2750,1800" fill="#166d8a"></polygon>
      <polygon points="2250,14900 2750,14400 26150,14400 26650,14900" fill="#166d8a"></polygon>

      <polygon points="13680,1800 13880,1300 15020,1300 15220,1800" fill="#50A6C2"></polygon>
      <polygon points="13680,14400 13880,14900 15020,14900 15220,14400" fill="#50A6C2"></polygon>


      <svg x="1750" y="1800" width="25400" height="12600">
        <rect x="0" y="0" width="25400" height="12600" fill="#50A6C2" />
        <line x1="12.5%" y1="0" x2="12.5%" y2="100%" strokeWidth="75" stroke="#ABABAB" strokeDasharray="300,200"/>
        <line x1="25%" y1="0" x2="25%" y2="100%" strokeWidth="75" stroke="#ABABAB" strokeDasharray="300,200"/>
        <line x1="37.5%" y1="0" x2="37.5%" y2="100%" strokeWidth="75" stroke="#ABABAB" strokeDasharray="300,200"/>
        <line x1="50%" y1="0" x2="50%" y2="100%" strokeWidth="75" stroke="#ABABAB" strokeDasharray="300,200"/>
        <line x1="62.5%" y1="0" x2="62.5%" y2="100%" strokeWidth="75" stroke="#ABABAB" strokeDasharray="300,200"/>
        <line x1="75%" y1="0" x2="75%" y2="100%" strokeWidth="75" stroke="#ABABAB" strokeDasharray="300,200"/>
        <line x1="87.5%" y1="0" x2="87.5%" y2="100%" strokeWidth="75" stroke="#ABABAB" strokeDasharray="300,200"/>
        <line x1="0" y1="25%" x2="100%" y2="25%" strokeWidth="75" stroke="#ABABAB" strokeDasharray="300,200"/>
        <line x1="0" y1="50%" x2="100%" y2="50%" strokeWidth="75" stroke="#ABABAB" strokeDasharray="300,200"/>
        <line x1="0" y1="75%" x2="100%" y2="75%" strokeWidth="75" stroke="#ABABAB" strokeDasharray="300,200"/>

        <g id="d" dangerouslySetInnerHTML={{ __html: diagram }}></g>

      </svg>
    </svg>
  )
 
};

export default PoolTable;