import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import axios from "axios";
import './drill.css';
import PoolTable from "../shared/poolTable/PoolTable";

const Drill = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


  const handleLevelChange = (num) => {
    setLevel(num)
    setDrill(drills[num-1])
  }
  
  const params = useParams();
 
  const [drills, setDrills] = useState([])
  const [drill, setDrill] = useState({'name': '', 'diagram': '', 'description': ''})
  const [level, setLevel] = useState(1)

  useEffect(() => {
    axios.get(API_ENDPOINT + "/topics/" + params.id).then(response => {
      setDrills(response.data.drills)
      setDrill(response.data.drills[0])
    })
  }, [params.id, API_ENDPOINT])

  console.log(drills)

  return (
    <div className="drillShow">
      <h1 className="drillName">{drill.name}</h1>
      
      <div className="drillBody">
        <div className="drillLevels">
          <p className={level == 1 ? "drillLevel activeLevel" : "drillLevel"} onClick={() => handleLevelChange(1)}>Chill</p>
          {drills[1] && <p className={level == 2 ? "drillLevel activeLevel" : "drillLevel"} onClick={() => handleLevelChange(2)}>Sophisticated</p>}
          {drills[2] && <p className={level == 3 ? "drillLevel activeLevel" : "drillLevel"} onClick={() => handleLevelChange(3)}>Beast</p>}
        </div>
        <PoolTable diagram={drill.diagram} styles={{backgroundColor: "white", borderRadius: "0.5rem", margin: "1rem 0"}}/>
      <div className="drillDescription">{drill.description}</div>
      <div className="drillShowButtons">
        <Link to={"/drills/"+ drill.id + "/run"} className="button drillShowButton">start drill</Link>
        <Link to={"/drills/"+ drill.id + "/stats"}  className="button drillShowButton">show stats</Link>
      </div>
      </div>
    </div>
  )
 
};

export default Drill;