import "./stats.css"

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import LinearPercentage from "../helpers/linearPercentage/LinearPercentage";
import PoolTable from "../shared/poolTable/PoolTable";

const Stats = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

  const params = useParams();
  
  const [statsData, setStatsData] = useState({drill: {'name': '', 'diagram': '', 'description': ''}});

  useEffect(() => {
    axios.get(API_ENDPOINT + "/drills/" + params.id + "/stats").then(response => {
      setStatsData(response.data)
      console.log(response.data)
    })
  }, [params.id, API_ENDPOINT])

  return(
    <>
      <div className="runShow">
        <h1 className="drillName">{statsData.drill.name}</h1>
        <div className="drillBody">
          <PoolTable diagram={statsData.drill.diagram} styles={{backgroundColor: "white", borderRadius: "0.5rem", margin: "1rem 0"}}/>
          <p className="boxLabel">Last {statsData.last5runs?.length} runs</p>
          <div className="last5runs">
            {statsData.last5runs?.map((o, i) => (
              <div className="linearPercentageWrapper" key={i}>
                <LinearPercentage percentage={o / statsData.shots.length * 100}/>
                <p className="linearPercentageLabel">{o + "/" + statsData.shots.length}</p>
              </div>
            ))}
          </div>
          <div className="statsRow">
            <div className="numberBox">
              <p className="boxLabel">average makes</p>
              <p className="numberBoxNumber">{statsData.last5runs?.length > 0 ? statsData.last5runs?.reduce( ( p, c ) => p + c, 0 ) / statsData.last5runs?.length : "-"}</p>
            </div>
            <div className="numberBox">
              <p className="boxLabel">perfect runs</p>
              <p className="numberBoxNumber">{statsData.perfectRuns}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default Stats;