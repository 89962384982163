import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom"

import { FiCrosshair } from "react-icons/fi";
import "./home.css"

const Home = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

  const [topics, setTopics] = useState([]);

  useEffect(() => {
    axios.get(API_ENDPOINT + "/topics/").then(response => {
      setTopics(response.data.topics)
    })
  }, [API_ENDPOINT])


  return (
    <div className="pathContainer">
      {topics.map((topic, i) => (
        <Link to={"drills/" + topic.id} className="pathPoint" key={i}>
          <FiCrosshair className="pathPointIcon"/>
          <p className="pathPointName">{topic.name}</p>
        </Link>
      ))}
    </div>
  )
};

export default Home;