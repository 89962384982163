import { Link } from "react-router-dom"
import "./header.css";
import { FiArrowLeft, FiUser } from "react-icons/fi";

const Header = () => {
  
  
  return (
    <div className="header">
      <Link to={-1}><FiArrowLeft className="headerIcon" id="" /></Link>
      <Link to="/">
        <h1 className="headerTitle">Training</h1>
      </Link>
      <Link to="/user"><FiUser className="headerIcon" id="" /></Link>
    </div>
  )
 
};

export default Header;