import { useState, useEffect } from "react";
import axios from "axios";
import './user.css';
import LinearPercentage from "../helpers/linearPercentage/LinearPercentage";

const User = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

  const [userData, setUserData] = useState({email: ''})
  const [progressData, setProgressData] = useState([])

  useEffect(() => {
    axios.get(API_ENDPOINT + "/user").then(response => {
      setUserData({email:response.data.email})
      setProgressData(response.data.progress)
    })
  }, [API_ENDPOINT])

  const handleLogOut = () => {
    localStorage.removeItem("jwt");
    window.location = "/";
  }

  console.log(progressData)
  console.log(userData)

  return (
    <div className="userShow">
      <p className="userName">Hello {userData.email}</p>
      {progressData.map((progress, i) => (
        <div className="progressWrapper" key={i}>
          <p className="progressChartLabel">{progress.name} - Level {progress.level_id}</p>
          <div className="progressBarChart">
            <LinearPercentage percentage={progress.score} className={"progressBar"}/>
            <p className="progressScore">{progress.score}%</p>
          </div>
        </div>
      ))}

      <div onClick={()=> handleLogOut()}>log out...</div>
    </div>
  )
 
};

export default User;